import React from 'react';
import { graphql } from 'gatsby';
import PostsList from '../components/PostsList';
import Layout from '../components/Layout';
import styles from './styles.module.css';

const PagesIndex = ({ data, location }) => (
  <Layout location={location}>
    <div className={styles.content}>
      <div className={styles.header}>
        <h1 className={styles.title}>We’re working to simplify pharmacy</h1>
        <p className={styles.blurb}>
          This is our product and engineering blog.
        </p>
      </div>
      <PostsList posts={data.posts} />
    </div>
  </Layout>
);

export const query = graphql`
  query {
    blurb: allMarkdownRemark(
      filter: { fields: { path: { eq: "/pages/homepage/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            canonical
          }
          html
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { isPost: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            date(formatString: "D MMMM YYYY")
            author
            excerpt
            canonical
            image {
              childImageSharp {
                fixed(width: 1560, height: 560) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          fields {
            path
          }
        }
      }
    }
  }
`;

export default PagesIndex;
