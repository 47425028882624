import React from 'react';
import { Link } from 'gatsby';

import styles from './styles.module.css';

const PostImage = (props) => {
  return (
    <div
      className={styles.postImage}
      style={{ backgroundImage: `url(${props.path})` }}
    />
  );
};

const PostsLists = (props) => {
  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {props.posts.edges.map(({ node }) => (
          <li className={styles.postWrapper} key={node.id}>
            <Link to={node.fields.path} className={styles.postLink}>
              <div className={styles.post}>
                {node.frontmatter.image && (
                  <PostImage
                    path={node.frontmatter.image.childImageSharp.fixed.src}
                  />
                )}
                <div className={styles.cardContent}>
                  <h2 className={styles.postTitle}>{node.frontmatter.title}</h2>
                  <span className={styles.excerpt}>{(node.frontmatter.excerpt) ? node.frontmatter.excerpt : node.excerpt }</span>
                  <span className={styles.meta}>
                    <time>{node.frontmatter.date}</time>
                    {node.frontmatter.author &&
                      ` by ${node.frontmatter.author}`}
                  </span>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PostsLists;
